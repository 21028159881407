import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import type { Observable } from "rxjs";
import classnames from "classnames";
import { type ComponentProps, type ReactNode } from "react";

import { CompletionRequirement, type ProcessingStates } from "graphql_globals";
import DocumentUploadHandler, {
  type DocumentUploaderHandlerRenderProps,
} from "common/document/multidoc_uploader/document_upload_handler";
import MultiUploader, {
  type UploadedDocument,
} from "common/document/multidoc_uploader/multi_uploader";
import { useMobileScreenClass } from "common/core/responsive";
import { useIsAuthenticated } from "common/authentication";
import type {
  Branding_viewer_referralInfo_organizationBrand as OrganizationBrand,
  Branding_viewer_referralInfo_publicOrganization as Organization,
} from "common/account/guest_signup/transaction_query.graphql";
import { NotarizeNetwork } from "common/core/logo/notarize_network";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import TosV2 from "common/tos";
import { useFeatureFlag } from "common/feature_gating";
import { AVAILABLE_FEATURES } from "constants/organization";

import Styles from "./index.module.scss";
import {
  SplitLayoutHeading,
  SplitLayoutSubheading,
  SplitLayoutSteps,
  SplitLayoutActionButton,
} from "../shared/split_layout";

const MESSAGES = defineMessages({
  tosActionText: {
    id: "a7c6e3b9-5f5b-4e2a-8f6a-2d3a1c7a5b3a",
    defaultMessage: "By adding a document and clicking 'Continue',",
  },
  headerNotary: {
    id: "7930ae2e-26e2-4e95-90e4-18677fb243c1",
    defaultMessage: "Connect with an online notary, now.",
  },
  headerNotaryANeed: {
    id: "8abf003a-9fcb-476a-b035-1702530720e9",
    defaultMessage: "Need a notary? Connect online now.",
  },
  headerNotaryBNotarize: {
    id: "d42ae5ca-2cae-4490-9703-4f8537cbc85e",
    defaultMessage: "Notarize your document online.",
  },
  headerEsignDocsProvided: {
    id: "c329c166-a63a-45dd-86d3-abde509f0f92",
    defaultMessage: "eSign your document",
  },
  headerEsign: {
    id: "a7fe53a8-91b3-435f-911b-35851ada951f",
    defaultMessage: "Upload documents for eSign",
  },
  headerNSTNotary: {
    id: "a8a1daa0-cd34-4fe8-9d16-24055449b7c0",
    defaultMessage: "Connect with a notary at {orgName}",
  },
});

type Props = {
  uploadStrategy: (file: File) => Observable<
    {
      id: string;
      name: string;
      status: ProcessingStates;
      mimeType: string;
      processingError?: string;
      classification?: { category: string | null; languages: string[] } | null;
      metadata: { pagesTotal: number | null } | null;
    }[]
  >;
  completeStrategy: (
    documents: { id: string; name: string; bundlePosition: number }[],
  ) => Promise<unknown>;
  completionRequirement?: CompletionRequirement;
  paymentCoveringOrgName?: string;
  organizationBrand: OrganizationBrand | null;
  orgFeatureFlags: Organization["featureFlags"];
  children?: ReactNode;
  signerPrice?: number | null;
  analyticsPrefix?: string;
  providedDocuments?: { url: string }[];
  onProvidedDocumentsContinue?: () => void;
  onProvidedDocumentsPreview?: () => void;
  isRetail?: boolean;
  onDocumentDeleteCb?: (doc: UploadedDocument) => void;
  showIneligibleWarning?: boolean;
  forNst: boolean;
};

export default function GuestUpload(props: Props) {
  const {
    uploadStrategy,
    completeStrategy,
    completionRequirement = CompletionRequirement.NOTARIZATION,
    paymentCoveringOrgName,
    organizationBrand,
    orgFeatureFlags = [],
    children,
    signerPrice,
    analyticsPrefix,
    providedDocuments,
    onProvidedDocumentsContinue,
    onProvidedDocumentsPreview,
    forNst,
    isRetail,
    onDocumentDeleteCb,
    showIneligibleWarning,
  } = props;
  const isNotarization = completionRequirement === CompletionRequirement.NOTARIZATION;
  const isMobile = useMobileScreenClass();
  const isAuthenticated =
    useIsAuthenticated() && !(organizationBrand?.header && organizationBrand.organizationLogoUrl);
  const signerPriceString = signerPrice ? `$${(signerPrice / 100).toFixed(2)}` : null;
  const hasDocsProvided = providedDocuments && providedDocuments.length > 0;
  const docsProvidedPreviewExptEnabled = useFeatureFlag(
    "signer-preview-login-docs-provided-easylink",
  );

  const organizationFeatures = orgFeatureFlags.filter((f) => f.value === "true").map((f) => f.key);
  const docsProvidedPreviewCopyExptEnabled =
    hasDocsProvided &&
    docsProvidedPreviewExptEnabled &&
    organizationFeatures.includes(AVAILABLE_FEATURES.DOCS_PROVIDED_EASYLINK_EXPT);

  const steps: ComponentProps<typeof SplitLayoutSteps>["steps"] = [];
  if (hasDocsProvided) {
    steps.push({
      heading: docsProvidedPreviewCopyExptEnabled ? (
        <FormattedMessage
          id="09584bb1-a094-4e53-a347-b06cbdc2e214"
          defaultMessage="Review and edit the document template"
        />
      ) : (
        <FormattedMessage
          id="7d17d3ce-80b3-4180-ac72-f76aa0f246f2"
          defaultMessage="Review your document"
        />
      ),
      info: docsProvidedPreviewCopyExptEnabled ? (
        <FormattedMessage
          id="d68eb78c-7de0-4998-a03a-0925ded689f6"
          defaultMessage="We provide an editable document template. Fill in what you can."
        />
      ) : (
        <FormattedMessage
          id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
          defaultMessage="Read the contents of the document before signing{isNotarization, select, true { in front of the notary.} other {.}}"
          values={{ isNotarization }}
        />
      ),
    });
  } else {
    steps.push({
      heading: (
        <FormattedMessage
          id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
          defaultMessage="Upload or scan your document"
        />
      ),
      info: (
        <FormattedMessage
          id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
          defaultMessage="Upload the full document as a .pdf or .docx file."
        />
      ),
    });
  }
  if (isNotarization) {
    steps.push(
      {
        heading: (
          <FormattedMessage
            id="0577d8ec-f842-47e4-9525-4d1084ee5dcd"
            defaultMessage="Verify your identity"
          />
        ),
        info: (
          <FormattedMessage
            id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
            defaultMessage="Answer a few questions and take a photo of your unexpired government issued ID."
          />
        ),
      },
      {
        heading: (
          <FormattedMessage
            id="3a9b2ec0-dd76-4760-996a-a0a16f85a509"
            defaultMessage="Connect with a notary on a video call"
          />
        ),
        info: (
          <FormattedMessage
            id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
            defaultMessage="Notaries are based in Virginia, Nevada, Florida, and Texas, and can notarize documents for use in all 50 states."
          />
        ),
      },
    );
  } else {
    steps.push({
      heading: (
        <FormattedMessage
          id="9c5d5f3d-5d2f-4648-ace2-f734b8e3eef4"
          defaultMessage="Apply your signature"
        />
      ),
      info: (
        <FormattedMessage
          id="8dd7ddbc-f42a-4f2d-8c6a-bb064c53f00d"
          defaultMessage="Select a digital signature and apply it to the document."
        />
      ),
    });
  }
  steps.push({
    heading: (
      <FormattedMessage
        id="27445c5f-8447-4679-86fb-819b247597a2"
        defaultMessage="Download or send document to another person"
      />
    ),
    info: (
      <FormattedMessage
        id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
        defaultMessage="Share your documents within seconds."
      />
    ),
  });

  return (
    <>
      <div
        className={classnames(Styles.multiUpload, {
          [Styles.authenticatedWrapper]: isAuthenticated,
        })}
      >
        <DocumentUploadHandler uploadStrategy={uploadStrategy}>
          {({
            uploadedDocuments$,
            onSelectFiles,
            onDocumentDelete,
          }: DocumentUploaderHandlerRenderProps) => {
            return (
              <>
                <div className={Styles.top} data-growth-id="upload-container">
                  <div className={Styles.innerTop}>
                    <div className={Styles.column}>
                      <SplitLayoutHeading data-growth-id="upload-heading">
                        {UploadHeading({
                          isNotarization,
                          hasDocsProvided,
                          forNst,
                          orgName: organizationBrand?.organizationName,
                        })}
                      </SplitLayoutHeading>

                      <SplitLayoutSubheading data-growth-id="upload-subheading">
                        {forNst ? (
                          <FormattedMessage
                            id="6fd8897c-36f4-4158-b602-85fccc23a8df"
                            defaultMessage="Connect with a notary at {orgName}, to get your document notarized by the right professional."
                            values={{ orgName: organizationBrand?.organizationName }}
                          />
                        ) : isNotarization ? (
                          paymentCoveringOrgName ? (
                            <FormattedMessage
                              id="f83621e3-cd4e-416b-a0bf-f5994f35a219"
                              defaultMessage="Connect with the {notarizeNetworkImage} on Proof 24/7 and notarize your document in minutes. {coveringOrgName} will cover the cost of this transaction - there will be no cost to you."
                              values={{
                                coveringOrgName: paymentCoveringOrgName,
                                notarizeNetworkImage: <NotarizeNetwork />,
                              }}
                            />
                          ) : (
                            <FormattedMessage
                              id="ec06f844-3585-47bc-bd80-edbc157efe2b"
                              defaultMessage="Connect with the {notarizeNetworkImage} on Proof 24/7 and notarize {docsProvidedPreviewCopyExptEnabled, select, true {this} other {your}} document in minutes{hasSignerPriceString, select, true {. {priceString}} other {.}}"
                              values={{
                                notarizeNetworkImage: <NotarizeNetwork />,
                                hasSignerPriceString: !!signerPriceString || isRetail,
                                priceString: (
                                  <FormattedMessage
                                    id="9dcfd67b-ab16-4dc8-a97c-36b3e9dab1df"
                                    defaultMessage="Price starts at {signerPriceString}."
                                    values={{
                                      signerPriceString:
                                        signerPriceString || (isRetail ? "$25" : null),
                                    }}
                                  />
                                ),
                                docsProvidedPreviewCopyExptEnabled,
                              }}
                            />
                          )
                        ) : paymentCoveringOrgName ? (
                          <FormattedMessage
                            id="6fd8897c-36f4-4158-b602-85fccc23a8df"
                            defaultMessage="Adopt an electronic signature and apply it to your documents. {coveringOrgName} will cover the cost of this transaction - there will be no cost to you."
                            values={{ coveringOrgName: paymentCoveringOrgName }}
                          />
                        ) : (
                          <FormattedMessage
                            id="b986344a-eed9-4307-82ea-f42cf771596d"
                            defaultMessage="Adopt an electronic signature and apply it to your documents{hasSignerPriceString, select, true {. {priceString}} other {.}}"
                            values={{
                              hasSignerPriceString: !!signerPriceString || isRetail,
                              priceString: (
                                <FormattedMessage
                                  id="2b256427-8d58-4f61-9c70-ab30c784c628"
                                  defaultMessage="Price starts at {signerPriceString}."
                                  values={{
                                    signerPriceString:
                                      signerPriceString || (isRetail ? "$4" : null),
                                  }}
                                />
                              ),
                            }}
                          />
                        )}
                      </SplitLayoutSubheading>

                      <div data-growth-id="notarization-steps">
                        <SplitLayoutSteps steps={steps} />

                        {isMobile && !hasDocsProvided && <Tos />}

                        {!isMobile && hasDocsProvided && onProvidedDocumentsContinue && (
                          <SplitLayoutActionButton onClick={onProvidedDocumentsContinue}>
                            {isNotarization ? (
                              docsProvidedPreviewCopyExptEnabled ? (
                                <FormattedMessage
                                  id="2967f53a-9952-446c-aff3-a329179a4e87"
                                  defaultMessage="Review and edit"
                                />
                              ) : (
                                <FormattedMessage
                                  id="a81d1146-18b9-48ba-a22b-11026d4ed793"
                                  defaultMessage="Notarize now"
                                />
                              )
                            ) : (
                              <FormattedMessage
                                id="50e52c50-9eb9-4fcc-8fbf-4b3f7deb1d8c"
                                defaultMessage="Sign now"
                              />
                            )}
                          </SplitLayoutActionButton>
                        )}
                      </div>
                    </div>

                    {!isMobile &&
                      (hasDocsProvided ? (
                        <div className={Styles.column}>
                          <div className={Styles.previewContainer}>
                            <PDFViewer
                              className={Styles.previewViewer}
                              url={providedDocuments[0].url || ""}
                            />
                            <div className={Styles.previewButton}>
                              <ButtonStyledLink
                                onClick={() => {
                                  segmentTrack(
                                    SEGMENT_EVENTS.GUEST_UPLOAD_LOGIN_PREVIEW_PREVIEW_CLICK,
                                  );
                                  onProvidedDocumentsPreview?.();
                                }}
                                variant="tertiary"
                                buttonSize="condensed"
                                buttonColor="action"
                              >
                                <FormattedMessage
                                  id="e8b4d526-173b-474e-901b-dcb646232e0e"
                                  defaultMessage="Preview document"
                                />
                              </ButtonStyledLink>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={Styles.column} data-growth-id="uploader-desktop">
                          <MultiUploader
                            aria-label="Document uploader"
                            onSelectFiles={onSelectFiles}
                            onDocumentDelete={(doc) => {
                              if (onDocumentDeleteCb) {
                                onDocumentDeleteCb(doc);
                              }
                              onDocumentDelete(doc);
                            }}
                            uploadedDocuments$={uploadedDocuments$}
                            completeStrategy={completeStrategy}
                            enableScanning
                            rowsClassName={Styles.uploadRows}
                            rowsWrapperClassName={Styles.uploadRowsWrapper}
                            continueButtonClassName={Styles.uploadContinueButton}
                            analyticsPrefix={analyticsPrefix}
                            showIneligibleWarning={showIneligibleWarning}
                          />
                          <Tos />
                        </div>
                      ))}

                    {children}
                  </div>
                </div>
                {isMobile && (
                  <div className={Styles.bottom} data-growth-id="uploader-mobile">
                    <MultiUploader
                      aria-label="Document uploader"
                      onSelectFiles={onSelectFiles}
                      onDocumentDelete={(doc) => {
                        if (onDocumentDeleteCb) {
                          onDocumentDeleteCb(doc);
                        }
                        onDocumentDelete(doc);
                      }}
                      uploadedDocuments$={uploadedDocuments$}
                      completeStrategy={completeStrategy}
                      rowsClassName={Styles.uploadRowsMobile}
                      enableScanning
                      isAddButtonFixedMobile
                      completionRequirement={completionRequirement}
                      hasDocsProvided={hasDocsProvided}
                      onProvidedDocumentsContinue={onProvidedDocumentsContinue}
                      onProvidedDocumentsPreview={onProvidedDocumentsPreview}
                      showIneligibleWarning={showIneligibleWarning}
                    />
                  </div>
                )}
              </>
            );
          }}
        </DocumentUploadHandler>
      </div>
    </>
  );
}

function Tos() {
  const intl = useIntl();
  return (
    <TosV2
      textColor="subtle"
      size="small"
      automationId="guest-signup-terms-of-service"
      className={Styles.tos}
      underlined
      actionText={intl.formatMessage(MESSAGES.tosActionText)}
    />
  );
}

function UploadHeading({
  isNotarization,
  hasDocsProvided,
  forNst,
  orgName,
}: {
  isNotarization: boolean;
  hasDocsProvided?: boolean;
  forNst: boolean;
  orgName: string | undefined;
}) {
  const intl = useIntl();
  let heading;

  if (forNst) {
    heading = MESSAGES.headerNSTNotary;
  } else if (isNotarization) {
    heading = MESSAGES.headerNotary;
  } else {
    heading = hasDocsProvided ? MESSAGES.headerEsignDocsProvided : MESSAGES.headerEsign;
  }
  return intl.formatMessage(heading, { orgName });
}
