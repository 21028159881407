import "./instruction.scss";

import { useState, useCallback, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGrid } from "common/details/grid";

type Instruction = {
  id: string;
  creator: string | null;
  text: string;
};
type InstructionProps = {
  instruction: Partial<Instruction>;
  onDelete: (instructionId: string) => void;
  onSave: (newInstruction: Instruction) => void;
  isEditable: boolean;
  startEditing?: boolean;
  allowChanges?: boolean;
  onEditToggle: (value: boolean | undefined) => void;
};
type Props = {
  instructions: Instruction[];
  onDelete: InstructionProps["onDelete"];
  onSave: InstructionProps["onSave"];
  allowChanges?: InstructionProps["allowChanges"];
};

function NotaryInstruction({
  instruction,
  isEditable,
  startEditing,
  onDelete,
  onSave,
  onEditToggle,
  allowChanges,
}: InstructionProps) {
  const { id, creator, text } = instruction;
  const [inputValue, setInputValue] = useState(text);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(startEditing);

  const toggleEditing = useCallback(() => setIsEditing((h) => !h), []);

  const ref = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (!id) {
      ref.current?.scrollIntoView({ block: "center" });
    }
  }, [instruction]);

  useEffect(() => {
    inputValue && onEditToggle(isEditing);
    return () => onEditToggle(false);
  }, [isEditing]);

  return (
    <DeprecatedDetailGridSection>
      {allowChanges && !isEditing && isEditable && (
        <div className="Instruction--EditDeleteCta">
          <Button
            automationId="edit-notary-note"
            variant="tertiary"
            buttonColor="action"
            buttonSize="condensed"
            onClick={toggleEditing}
            withIcon={{ name: "pencil-line", placement: "left" }}
          >
            <FormattedMessage id="7faf09f3-77e1-4ee1-86be-c6be6ed2fa2c" defaultMessage="Edit" />
          </Button>

          <Button
            automationId="delete-notary-note"
            variant="tertiary"
            buttonColor="danger"
            buttonSize="condensed"
            onClick={() => onDelete(id!)}
            withIcon={{ name: "delete", placement: "left" }}
          >
            <FormattedMessage id="86118645-34fe-45dd-9484-19e7195c9f48" defaultMessage="Delete" />
          </Button>
        </div>
      )}
      {isEditing ? (
        <textarea
          className="NewInstructionTextarea"
          data-automation-id="notary-note"
          defaultValue={text}
          placeholder="Add a new note..."
          onChange={({ target }) => {
            setInputValue(target.value);
            setButtonDisabled(target.value === "");
          }}
          ref={ref}
        />
      ) : (
        <div className="Instruction--Content">
          <div className="Instruction--Content--creator">{creator}</div>
          <div className="Instruction--Content--text">{text}</div>
        </div>
      )}
      {isEditing && (
        <div className="ChangeActions">
          <Button
            className="ChangeActions--cancel"
            variant="tertiary"
            buttonColor="dark"
            buttonSize="condensed"
            onClick={toggleEditing}
          >
            <FormattedMessage id="283b775b-40f1-4273-9862-a5984dec6ecf" defaultMessage="Cancel" />
          </Button>
          <Button
            className="ChangeActions--save"
            variant="secondary"
            buttonColor="action"
            automationId="save-notary-note"
            onClick={() => {
              onSave({ ...instruction, text: inputValue } as Instruction);
              toggleEditing();
            }}
            disabled={buttonDisabled}
          >
            <FormattedMessage id="f5582f76-9dd8-43ef-a753-b746f91572fc" defaultMessage="Save" />
          </Button>
        </div>
      )}
    </DeprecatedDetailGridSection>
  );
}

function NotaryInstructionsGrid({ instructions, onDelete, onSave, allowChanges }: Props) {
  const [activeIndex, setActiveIndex] = useState<number | boolean | undefined>(undefined);
  const displayNotes = instructions.length || activeIndex === instructions.length;
  const hasNoInstructions = !instructions.length;
  const noActiveNote = !activeIndex && activeIndex !== 0;

  return (
    <div className="NotaryInstructionsGrid">
      {displayNotes && (
        <DeprecatedDetailGrid>
          {instructions.map((instruction, index) => (
            <NotaryInstruction
              key={index}
              instruction={instruction}
              isEditable={!activeIndex || index === activeIndex}
              onEditToggle={(isEditing) => setActiveIndex(isEditing && index)}
              onDelete={onDelete}
              onSave={onSave}
              allowChanges={allowChanges}
            />
          ))}
          {activeIndex === instructions.length && allowChanges && (
            <NotaryInstruction
              key={instructions.length}
              instruction={{}}
              isEditable
              startEditing
              onDelete={() => {}}
              onSave={onSave}
              onEditToggle={(isEditing) => setActiveIndex(isEditing && activeIndex)}
              allowChanges={allowChanges}
            />
          )}
        </DeprecatedDetailGrid>
      )}
      {noActiveNote && hasNoInstructions && (
        <Button
          variant="secondary"
          data-automation-id="add-first-new-instruction"
          buttonColor="action"
          onClick={() => setActiveIndex(instructions.length)}
          className="AddNewNoteButton"
          disabled={!allowChanges}
        >
          <FormattedMessage
            id="b65c0bc7-f49c-459b-b7db-859d22269e3b"
            defaultMessage="+ Add new note"
          />
        </Button>
      )}

      {noActiveNote && allowChanges && instructions.length > 0 && (
        <Button
          className="AddNewNoteTextButton"
          automationId="add-new-instruction"
          variant="tertiary"
          buttonColor="action"
          buttonSize="condensed"
          onClick={() => setActiveIndex(instructions.length)}
        >
          <FormattedMessage
            id="1ad039ec-b8a4-4f56-aee2-25f8ca82d254"
            defaultMessage="+ Add new note"
          />
        </Button>
      )}
    </div>
  );
}

export default NotaryInstructionsGrid;
